
export const initialFoodCrisisState = {
    phase: 2,
    year: 2022,
    month: 1
};

// ==============================|| EXPLORE REDUCER ||============================== //

const foodCrisisReducer = (state = initialFoodCrisisState, action) => {
  switch (action.type) {
    case "phase":
        return {
            ...state,
            phase: action.value,
        };
    case "year":
        return {
            ...state,
            year: action.value,
        };
    case "month":
        return {
            ...state,
            month: action.value,
        };

    default:
      return state;
  }
};

export default foodCrisisReducer;
