import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Trans } from "@lingui/macro";

import PropTypes from "prop-types";

// material-ui
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Paper from "@mui/material/Paper";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";

import CrisisAlertIcon from "@mui/icons-material/CrisisAlert";
import ExploreIcon from "@mui/icons-material/Explore";
import DashboardIcon from "@mui/icons-material/Dashboard";
import DownloadIcon from "@mui/icons-material/Download";
import InfoIcon from "@mui/icons-material/Info";

import Image from "mui-image";

import nasaLogo from "../../img/nasa.svg";
import umdLogo from "../../img/umd.svg";
import harvestLogo from "../../img/harvest.png";
import harvestLogoWhite from "../../img/harvest_white.png";

// third-party
// import PerfectScrollbar from 'react-perfect-scrollbar';
import { BrowserView, MobileView } from "react-device-detect";
import { Link, useNavigate, useLocation } from "react-router-dom";

const tabs = ["/foodcrisis", "/explore", "/dashboard", "/export", "/info"];

export const Sidebar = ({ drawerOpen, drawerToggle, window }) => {
  const coreState = useSelector((state) => state.core);
  const [value, setValue] = React.useState(1); // set the explore (the item is the 1 in the list)

  let navigate = useNavigate();
  let location = useLocation();

 
  const handleChange = (event, v) => {
    setValue(v);
    console.log(v)
    navigate(tabs[v]);
  };

  useEffect(() => {
    console.log(coreState);
    tabs.forEach((tab, index) => {
      if (tab === location.pathname) {
        setValue(index);
      }
    });
  }, []);

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: "105.025px",
      }}
    >
      <Toolbar />
      <Divider />

      <Tabs
        size="small"
        orientation="vertical"
        value={value}
        onChange={handleChange}
        textColor={coreState.theme.palette.fsp.main}
        indicatorColor={coreState.theme.palette.fsp.main}
        aria-label="icon tabs example"
      >
        <Tab
          sx={{ fontSize: "0.75em" }}
          icon={<CrisisAlertIcon color="teal" />}
          label={<Trans>Food Crisis</Trans>}
          aria-label="foodcrisis"
        />
        <Tab
          sx={{ fontSize: "0.75em" }}
          icon={<ExploreIcon />}
          label={<Trans>Explore</Trans>}
          aria-label="explore"
        />

        <Tab
          sx={{ fontSize: "0.75em" }}
          icon={<DashboardIcon />}
          label={<Trans>Dashboard</Trans>}
          aria-label="dashboard"
        />

        <Tab
          sx={{ fontSize: "0.75em" }}
          icon={<DownloadIcon />}
          label={<Trans>Export</Trans>}
          aria-label="export"
        />

        <Tab
          sx={{ fontSize: "0.75em" }}
          icon={<InfoIcon />}
          label={<Trans>Info</Trans>}
          aria-label="info"
        />
      </Tabs>

      <Box
        sx={{
          position: "absolute",
          bottom: "1rem",
        }}
      >
        <a href="https://nasaharvest.org" target="_blank" rel="noopener">
          <Image
            src={
              coreState.theme.palette.mode == "light"
                ? harvestLogo
                : harvestLogoWhite
            }
            height="4.5rem"
            width="105.025px"
            fit="contain"
          />
        </a>
      </Box>
    </Drawer>
  );
};

export const MobileNav = ({ drawerOpen, drawerToggle, window }) => {
  const coreState = useSelector((state) => state.core);
  const [value, setValue] = React.useState(0);

  let navigate = useNavigate();
  let location = useLocation();

  const handleChange = (event, v) => {
    setValue(v);
    navigate(tabs[v]);
  };

  useEffect(() => {
    tabs.forEach((tab, index) => {
      if (tab === location.pathname) {
        setValue(index);
      }
    });
  }, []);

  return (
    <Paper
      sx={{
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 10000,
      }}
      elevation={5}
    >
      <BottomNavigation showLabels value={value} onChange={handleChange}>
        <BottomNavigationAction
          label={<Trans>Explore</Trans>}
          aria-label="explore"
          icon={<ExploreIcon />}
        />
        <BottomNavigationAction
          label={<Trans>Dashboard</Trans>}
          aria-label="dashboard"
          icon={<DashboardIcon />}
        />
        <BottomNavigationAction
          label={<Trans>Export</Trans>}
          aria-label="export"
          icon={<DownloadIcon />}
        />
        <BottomNavigationAction
          label={<Trans>Info</Trans>}
          aria-label="info"
          icon={<InfoIcon />}
        />
        <BottomNavigationAction
          label={<Trans>Food Crisis</Trans>}
          aria-label="foodCrisis"
          icon={<InfoIcon />}
        />
      </BottomNavigation>
    </Paper>
  );
};
