import React, { useState, useEffect } from "react";

import { Trans } from "@lingui/macro";

import { useDispatch, useSelector } from "react-redux";
import { createTheme } from "@mui/material/styles";

import glamLogoClipped from "../../img/GLAM2-Logo-clipped.png";
import glamLogoFull from "../../img/GLAM2-Logo-white.png";
import fspLogo from "../../img/fsp.png";

import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Badge from "@mui/material/Badge";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import AccountCircle from "@mui/icons-material/AccountCircle";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MailIcon from "@mui/icons-material/Mail";
import NotificationsIcon from "@mui/icons-material/Notifications";
import TranslateIcon from "@mui/icons-material/Translate";
import MoreIcon from "@mui/icons-material/MoreVert";
import HelpIcon from "@mui/icons-material/Help";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import LightModeIcon from "@mui/icons-material/LightMode";
import LoginIcon from "@mui/icons-material/Login";
import CloseIcon from "@mui/icons-material/Close";

import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import useMediaQuery from "@mui/material/useMediaQuery";

import { Link } from "react-router-dom";

import { pink, yellow } from "@mui/material/colors";

import Image from "mui-image";

import { AnnouncementsDialog } from "./Announcements";

const LanguageSelector = () => {
  const state = useSelector((state) => state.core);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const dispatch = useDispatch();

  const open = Boolean(anchorEl);

  const languageOptions = [
    { value: "en", text: "English" },
    { value: "es", text: "Español" },
    { value: "fr", text: "Français" },
    { value: "hi", text: "हिन्दी" },
    { value: "pt", text: "Português" },
    { value: "zh-cn", text: "简化字" },
  ];

  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event, index) => {
    console.log(languageOptions[index].value);
    setSelectedIndex(index);
    dispatch({ type: "language", value: languageOptions[index].value });
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        size="large"
        aria-label="select language"
        color="inherit"
        onClick={handleClickListItem}
      >
        <Badge badgeContent={state.language} color="success">
          <TranslateIcon />
        </Badge>
      </Button>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {languageOptions.map((option, index) => (
          <MenuItem
            key={index}
            selected={index === selectedIndex}
            onClick={(event) => handleMenuItemClick(event, index)}
          >
            <Avatar
              sx={{
                bgcolor: state.theme.palette.success.light,
                mr: 1,
              }}
            >
              <Typography variant="overline">{option.value}</Typography>
            </Avatar>

            {option.text}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export const Bar = () => {
  const state = useSelector((state) => state.core);
  const dispatch = useDispatch();
  const matches = useMediaQuery(state.theme.breakpoints.up("md"));

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const [helpOpen, setHelpOpen] = useState(false);

  const closeHelp = () => {
    setHelpOpen(false);
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleNotifications = (event) => {
    dispatch({ type: "announcements", value: true });
  };

  const handleBrightness = () => {
    console.log(state.theme.palette.mode);
    if (state.theme.palette.mode === "dark") {
      dispatch({
        type: "theme",
        value: createTheme({
          palette: {
            gold: {
              light: "#fffe76",
              main: "#fecb44",
              dark: "#c79b00",
            },
          },
        }),
      });
    } else {
      dispatch({
        type: "theme",
        value: createTheme({
          palette: {
            gold: {
              light: "#fffe76",
              main: "#fecb44",
              dark: "#c79b00",
            },
            mode: "dark",
          },
        }),
      });
    }
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>
        <Trans>Profile</Trans>
      </MenuItem>
      <MenuItem onClick={handleMenuClose}>
        <Trans>My account</Trans>
      </MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={handleBrightness}>
        <IconButton
          size="large"
          aria-label="dark mode"
          color="inherit"
          value={state.theme}
        >
          {state.theme.palette.mode === "dark" ? (
            <LightModeIcon sx={{ color: yellow[500] }} />
          ) : (
            <DarkModeIcon sx={{ color: yellow[500] }} />
          )}
        </IconButton>
        <p>
          <Trans>Change Theme</Trans>
        </p>
      </MenuItem>

      <MenuItem onClick={handleNotifications}>
        <IconButton size="large" onClick={handleNotifications} color="inherit">
          <Badge
            badgeContent={
              state.announcementList ? state.announcementList.length : 0
            }
            color="gold"
          >
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>
          <Trans>Notifications</Trans>
        </p>
      </MenuItem>

      <MenuItem>
        <IconButton size="large">
          <TranslateIcon />
        </IconButton>
        <p>
          <Trans>Language</Trans>
        </p>
      </MenuItem>

      <MenuItem
        onClick={() => {
          setHelpOpen(true);
        }}
      >
        <IconButton size="large" color="inherit">
          <HelpIcon />
        </IconButton>
        <p>
          <Trans>Help</Trans>
        </p>
      </MenuItem>

      <MenuItem>
        <IconButton size="large" color="inherit" disabled>
          <AccountCircle />
        </IconButton>
        <p>
          <Trans>Account</Trans>
        </p>
      </MenuItem>
    </Menu>
  );

  return (
    <div>
      <AppBar
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        style={{backgroundColor: state.theme.palette.fsp.main}}
      >
        <Toolbar>
          <Box sx={{ display: { xs: "block" } }}>
            <Link to="/">
              <Image
                width="4rem"
                style={{
                  margin:".25rem"
                }}
                duration={0}
                src={fspLogo}
              />
            </Link>
          </Box>

          <Box sx={{ flexGrow: 1 }}>
            {matches ? (
              <Typography
                variant="h6"
                sx={{
                  fontFamily: "Kiona",
                  fontWeight: 600,
                  marginLeft: "1rem",
                }}
              >
                <Trans>Control Panel for Risk Monitoring</Trans>
              </Typography>
            ) : null}
          </Box>

          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            <Tooltip title={<Trans>Change Dark/Light Mode</Trans>} arrow>
              <IconButton
                size="large"
                aria-label="dark mode"
                onClick={handleBrightness}
                color="inherit"
                value={state.theme}
              >
                {state.theme.palette.mode === "dark" ? (
                  <LightModeIcon sx={{ color: yellow[500] }} />
                ) : (
                  <DarkModeIcon sx={{ color: yellow[500] }} />
                )}
              </IconButton>
            </Tooltip>
            <IconButton
              size="large"
              onClick={handleNotifications}
              color="inherit"
            >
              <Badge
                badgeContent={
                  state.announcementList ? state.announcementList.length : 0
                }
                color="warning"
              >
                <NotificationsIcon />
              </Badge>
            </IconButton>
            <LanguageSelector />

            <IconButton
              size="large"
              color="inherit"
              onClick={() => {
                setHelpOpen(true);
              }}
            >
              <HelpIcon />
            </IconButton>

            <Dialog open={helpOpen} onClose={closeHelp}>
              <DialogTitle>
                <ListItem>
                  <ListItemIcon>
                    <HelpIcon fontSize="large" color="info" />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography variant="h5">
                        <Trans>Help with the Control Panel</Trans>
                      </Typography>
                    }
                  />
                </ListItem>

                <IconButton
                  onClick={closeHelp}
                  sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent dividers sx={{ display: "flex" }}>
                <Typography>
                  <Trans>
                    For details on how to use this application & more
                    information about FSP control panel, please see the
                  </Trans>{" "}
                  <Link to="/info">
                    <Trans>INFO</Trans>
                  </Link>{" "}
                  <Trans>page.</Trans> <br />
                  <br />
                  <Trans>
                    If you encounter an error with the application or need
                    additional assistance, please contact
                  </Trans>{" "}
                  <a href="mailto:glam-system-help@googlegroups.com">
                    glam-system-help@googlegroups.com
                  </a>
                </Typography>
              </DialogContent>
            </Dialog>

            <AnnouncementsDialog />
            <Tooltip title={<Trans>User Accounts Coming Soon</Trans>} arrow>
              <span>
                <IconButton
                  size="large"
                  edge="end"
                  aria-label="account of current user"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  // onClick={handleProfileMenuOpen}
                  color="inherit"
                  disabled
                >
                  <AccountCircle />
                </IconButton>
              </span>
            </Tooltip>
          </Box>
          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>

      {renderMobileMenu}
      {renderMenu}
    </div>
  );
};
