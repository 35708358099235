import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useLocation } from "react-router-dom";

import moment from "moment";

import { Trans } from "@lingui/macro";

import Image from "mui-image";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import LinearProgress from "@mui/material/LinearProgress";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import ImageIcon from "@mui/icons-material/Image";
import ConstructionIcon from "@mui/icons-material/Construction";
import SyncIcon from "@mui/icons-material/Sync";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import CampaignIcon from "@mui/icons-material/Campaign";
import ArticleIcon from "@mui/icons-material/Article";
import NewReleasesIcon from "@mui/icons-material/NewReleases";

import Carousel from "react-material-ui-carousel";
import { fetchRetry } from "../Utils/Utils";

const AnnouncementIcons = {
  update: <HourglassTopIcon color="info" fontSize="large" sx={{ p: 3 }} />,
  feature: <NewReleasesIcon color="info" fontSize="large" sx={{ p: 3 }} />,
  documentation: <ArticleIcon color="info" fontSize="large" sx={{ p: 3 }} />,
};

const AnnouncementMessage = ({ message }) => {
  const URL_REGEX =
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;

  let parsed = message.split(" ").map((part, index) =>
    URL_REGEX.test(part) ? (
      <a href={part.split("?")[0]} target="_blank" key={index}>
        {part.split("?")[1]
          ? part.split("?")[1].replaceAll("-", " ")
          : part.split("?")[0]}
      </a>
    ) : (
      part + " "
    )
  );

  return <Typography>{parsed}</Typography>;
};

const announcementList = [
  {
    header: "Welcome to the Control Panel for Risk Monitoring",
    message: "We are excited to announce the launch of the Food Crisis feature within our Risk Monitoring Control Panel. This innovative tool leverages advanced machine learning algorithms, incorporating over 200 data inputs, to forecast potential food crises with increased precision. By selecting a specific geographic area within the portal, users will gain access to model outputs including the Integrated Food Security Phase Classification (IPC) phases and the primary factors contributing to food insecurity. To explore this feature and understand how it can inform your policy development and research, please visit the INFO tab on the Food Security Portal.",
    date: "2024-03-26",
    sticky: true,
    tags: ["feature"],
    image: null,
  },
  {
    header: "Launch of the Enhanced FSP Control Panel for Risk Monitoring in Partnership with NASA Harvest",
    message: "We are thrilled to announce the launch of the new version of the Food Security Portal (FSP) Control Panel for Risk Monitoring. This release is a significant milestone as it marks a robust collaboration between our team and the NASA Harvest project. This updated version introduces cutting-edge satellite imagery and geospatial data analytics provided by NASA Harvest. These enhancements are designed to improve the accuracy and timeliness of risk assessments related to agricultural production and food security.",
    date: "2024-03-26",
    sticky: true,
    tags: ["feature"],
    image: null,
  },
];

const Announcement = ({ entry }) => {
  useEffect(() => {
    console.log(entry.message);
    var matches = entry.message.match(/\bhttps?:\/\/\S+/gi);
  }, []);

  return (
    <Grid container spacing={1}>
      <Grid item xs={entry.image ? 4 : 2} sx={{ justifyContent: "center" }}>
        {entry.image ? (
          <Image width="100%" duration={0} src={entry.image} />
        ) : (
          AnnouncementIcons[entry.tags[0]]
        )}
      </Grid>
      <Grid item xs={entry.image ? 8 : 10}>
        <Typography variant="h6">{entry.header}</Typography>
        <AnnouncementMessage message={entry.message} />
      </Grid>
    </Grid>
  );
};

export const AnnouncementsDialog = () => {
  const coreState = useSelector((state) => state.core);
  const dispatch = useDispatch();

  const location = useLocation();

  const [announcements, setAnnouncements] = useState([]);

  const closeAnnouncements = () => {
    // setSnapshotData([]);
    dispatch({
      type: "announcements",
      value: false,
    });
  };

  return (
    <Dialog
      open={coreState.announcements && coreState.primed}
      // open={coreState.announcements && location.pathname == "/"}
      onClose={closeAnnouncements}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>
        <ListItem>
          <ListItemIcon>
            <CampaignIcon fontSize="large" color="info" />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography variant="h5">
                <Trans>News & Announcements</Trans>
              </Typography>
            }
          />
        </ListItem>
        <IconButton
          onClick={closeAnnouncements}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers sx={{ p: 2 }}>
        <Carousel
          animation="slide"
          cycleNavigation={true}
          stopAutoPlayOnHover={true}
          autoPlay={true}
          duration={1000}
          changeOnFirstRender={false}
        >
          {announcementList.map((annoucement, index) => (
            <Announcement key={index} entry={annoucement} />
          ))}
        </Carousel>
      </DialogContent>
      <DialogActions>
        <DialogActions>
          <Button onClick={closeAnnouncements}>
            <Trans>Dismiss</Trans>
          </Button>
        </DialogActions>
      </DialogActions>
    </Dialog>
  );
};
